import '../styles/globals.css';
import { appWithTranslation } from 'next-i18next';
import nextI18nConfig from '../next-i18next.config';

import AzureAppInsights from '../components/general/AzureAppInsights';
import { wrapper } from '../store/index';
import { Provider } from 'react-redux';

const App = ({ Component, ...rest }) => {
  const { store, props } = wrapper.useWrappedStore(rest);
  return (
    <AzureAppInsights>
      <Provider store={store}>
        <Component {...props.pageProps} />
      </Provider>
    </AzureAppInsights>
  );
};

// https://github.com/i18next/next-i18next#unserializable-configs
export default appWithTranslation(App, nextI18nConfig);
